//城市对应市政府的经纬度
const cityLaction = {

    '广州市': {//广州的为中和广场的经纬度
        lng: 113.335593,
        lat: 23.119767,
    },
    '韶关市': {
        lng: 113.603828,
        lat: 24.816859,
    },
    '深圳市': {
        lng: 114.064505,
        lat: 22.54918,
    },
    '珠海市': {
        lng: 113.583533,
        lat: 22.276826,
    },
    '汕头市': {
        lng: 116.688522,
        lat: 23.359958,
    },
    '佛山市': {
        lng: 113.128212,
        lat: 23.027216,
    },
    '江门市': {
        lng: 113.087985,
        lat: 22.584959,
    },
    '湛江市': {
        lng: 110.364172,
        lat: 21.276354,
    },
    '茂名市': {
        lng: 110.932003,
        lat: 21.668524,
    },
    '肇庆市': {
        lng: 112.471528,
        lat: 23.053697,
    },
    '惠州市': {
        lng: 114.422152,
        lat: 23.117972,
    },
    '梅州市': {
        lng: 116.128403,
        lat: 24.294868,
    },
    '汕尾市': {
        lng: 115.382141,
        lat: 22.792864,
    },
    '河源市': {
        lng: 114.70677,
        lat: 23.750654,
    },
    '阳江市': {
        lng: 111.989759,
        lat: 21.863306,
    },
    '清远市': {
        lng: 113.062516,
        lat: 23.688306,
    },
    '东莞市': {
        lng: 113.758184,
        lat: 23.027314,
    },
    '中山市': {
        lng: 113.398916,
        lat: 22.523334,
    },
    '潮州市': {
        lng: 116.62794,
        lat: 23.663825,
    },
    '揭阳市': {
        lng: 116.379233,
        lat: 23.557378,
    },
    '云浮市': {
        lng: 112.051076,
        lat: 22.921065,
    }

}
export default cityLaction

// 以下是腾讯的经纬度

// {
//   "广州市": {
//     "lng": 113.264532,
//     "lat": 23.129484
//   },
//   "韶关市": {
//     "lng": 113.597594,
//     "lat": 24.821033
//   },
//   "深圳市": {
//     "lng": 114.058278,
//     "lat": 22.554118
//   },
//   "珠海市": {
//     "lng": 113.577304,
//     "lat": 22.281012
//   },
//   "汕头市": {
//     "lng": 116.68236,
//     "lat": 23.372961
//   },
//   "佛山市": {
//     "lng": 113.122064,
//     "lat": 23.021413
//   },
//   "江门市": {
//     "lng": 113.081875,
//     "lat": 22.578179
//   },
//   "湛江市": {
//     "lng": 110.357389,
//     "lat": 21.2708
//   },
//   "茂名市": {
//     "lng": 110.92422,
//     "lat": 21.66296
//   },
//   "肇庆市": {
//     "lng": 112.464764,
//     "lat": 23.046786
//   },
//   "惠州市": {
//     "lng": 114.416387,
//     "lat": 23.111022
//   },
//   "梅州市": {
//     "lng": 116.122655,
//     "lat": 24.287862
//   },
//   "汕尾市": {
//     "lng": 115.376383,
//     "lat": 22.785791
//   },
//   "河源市": {
//     "lng": 114.700948,
//     "lat": 23.741407
//   },
//   "阳江市": {
//     "lng": 111.984935,
//     "lat": 21.855124
//   },
//   "清远市": {
//     "lng": 113.056854,
//     "lat": 23.663939
//   },
//   "东莞市": {
//     "lng": 113.750387,
//     "lat": 23.021422
//   },
//   "中山市": {
//     "lng": 113.391129,
//     "lat": 22.514449
//   },
//   "潮州市": {
//     "lng": 116.621162,
//     "lat": 23.657596
//   },
//   "揭阳市": {
//     "lng": 116.372439,
//     "lat": 23.551146
//   },
//   "云浮市": {
//     "lng": 112.044657,
//     "lat": 22.915194
//   }
// }


//以下是百度的经纬度
// {
//     '广州市': {
//         lng: 113.270766,
//         lat: 23.135618,
//     },
//     '韶关市': {
//         lng: 113.603828,
//         lat: 24.816859,
//     },
//     '深圳市': {
//         lng: 114.064505,
//         lat: 22.54918,
//     },
//     '珠海市': {
//         lng: 113.583533,
//         lat: 22.276826,
//     },
//     '汕头市': {
//         lng: 116.688522,
//         lat: 23.359958,
//     },
//     '佛山市': {
//         lng: 113.128212,
//         lat: 23.027216,
//     },
//     '江门市': {
//         lng: 113.087985,
//         lat: 22.584959,
//     },
//     '湛江市': {
//         lng: 110.364172,
//         lat: 21.276354,
//     },
//     '茂名市': {
//         lng: 110.932003,
//         lat: 21.668524,
//     },
//     '肇庆市': {
//         lng: 112.471528,
//         lat: 23.053697,
//     },
//     '惠州市': {
//         lng: 114.422152,
//         lat: 23.117972,
//     },
//     '梅州市': {
//         lng: 116.128403,
//         lat: 24.294868,
//     },
//     '汕尾市': {
//         lng: 115.382141,
//         lat: 22.792864,
//     },
//     '河源市': {
//         lng: 114.70677,
//         lat: 23.750654,
//     },
//     '阳江市': {
//         lng: 111.989759,
//         lat: 21.863306,
//     },
//     '清远市': {
//         lng: 113.062516,
//         lat: 23.688306,
//     },
//     '东莞市': {
//         lng: 113.758184,
//         lat: 23.027314,
//     },
//     '中山市': {
//         lng: 113.398916,
//         lat: 22.523334,
//     },
//     '潮州市': {
//         lng: 116.62794,
//         lat: 23.663825,
//     },
//     '揭阳市': {
//         lng: 116.379233,
//         lat: 23.557378,
//     },
//     '云浮市': {
//         lng: 112.051076,
//         lat: 22.921065,
//     }
// }